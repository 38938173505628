.privacy {
    font-family: Arial, sans-serif;
    margin: 20px;
    line-height: 1.6;
}

h1,
h2 {
    text-align: center;
}

h2 {
    margin-top: 40px;
}

.section {
    margin-bottom: 30px;
}