.account-deletion-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.deletion-heading {
    text-align: center;
}

.deletion-section {
    margin-bottom: 20px;
}

h2 {
    font-size: 24px;
    color: #F4990F;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    line-height: 1.5;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

a {
    color: #F4990F;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}