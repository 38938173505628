body,
html {
  background-color: #1E1E1F;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  /* font-family: Pacifico, cursive; */

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #8C9093;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #292A2B;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #292A2B;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E5C51A;
}

::-webkit-scrollbar-corner {
  background-color: #8C9093;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textarea-ico {
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.animate-logo {
  animation: rotateLogo 4s linear infinite;
  transform: rotate(360deg)
}

@keyframes rotateLogo {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.error-img {
  width: 100%;
  height: max-content;
  background-image: url('../src/assets/img/logo.png');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}

@tailwind base;
@tailwind components;

.theme-light {
  /* --color-primary: #1E1E1F; */
  --color-primary: #1E1E1F;
  --color-secondary: #292A2B;
  --color-color1: #EE4B49;
  --color-color2: #FFFFFF;
  --color-color3: #120707;
  --color-color4: #E5C51A;
  --color-color5: #8C9093;
  --color-color6: #02225B;
  --color-color7: #448FF2;
  --color-color8: #D9D0D4;
  --color-gray-light: #4e4e4e;
}

.theme-dark {
  --color-primary: #1d1d1d;
  --color-secondary: #423f3a;
  --color-color1: #EE4B49;
  --color-color2: #FFFFFF;
  --color-color3: #120707;
  --color-color4: #E5C51A;
  --color-color5: #8C9093;
  --color-color6: #02225B;
  --color-color7: #448FF2;
  --color-color8: #D9D0D4;
  --color-gray-light: #4e4e4e;
}

@tailwind utilities;